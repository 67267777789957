type BreadCrumb = {
  id: string
  name: string
  path: string
}
export const useBreadCrumbStore = defineStore('bread-crumb-store', () => {
  const breadCrumb = ref<BreadCrumb[]>([] as BreadCrumb[])
  const page = ref<number>(1)
  const addBreadCrumb = (breadCrumbArg: BreadCrumb) => {
    breadCrumb.value.push(breadCrumbArg)
  }
  const setBreadCrumbs = (breadCrumbArg: BreadCrumb[]) => {
    breadCrumb.value = breadCrumbArg.concat()
  }
  const setPage = (pageArg: number) => {
    page.value = pageArg
  }
  const flushBreadCrumb = () => {
    breadCrumb.value = []
  }
  const getBreadCrumbs = computed(() => {
    const pageBread =
      page.value === 1
        ? []
        : [
            {
              id: `page-${page.value}`,
              name: `${page.value}ページ目`,
              path: `${breadCrumb.value[-1]?.path}?page=${page.value}`,
            },
          ]
    return [...breadCrumb.value, ...pageBread]
  })
  const getLastElement = computed(() => {
    return breadCrumb.value[breadCrumb.value.length - 1]
  })
  return {
    breadCrumb,
    setBreadCrumbs,
    addBreadCrumb,
    getBreadCrumbs,
    flushBreadCrumb,
    getLastElement,
    setPage,
  }
})
